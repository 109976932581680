@import '../utils/base';

.react-tagsinput {
  background-color: var(--input-color);
  border: 1px solid var(--input-border-color);
  border-radius: .25rem;
  overflow: hidden;
  min-height: 2.6rem;
  padding: .5rem 0 0 1rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-tagsinput--focused {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgb(70 150 229 / 25%);
}

.react-tagsinput-tag {
  font-size: 1rem;
  background-color: #f1f1f1;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  margin: 0 5px 6px 0;
  padding: 6px 8px;
  line-height: 1;
  color: #ffffff;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: 700;
  margin-left: 8px;
}

.react-tagsinput-tag span:before {
  content: '\2715';
  color: #ffffff;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  outline: none;
  padding: 1px 0;
  width: 100%;
  margin-bottom: 6px;
  font-size: 1.25rem;
  color: var(--input-text-color);
}

.react-tagsinput-input::placeholder {
  color: $textPlaceholder;
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--active-color);
}
